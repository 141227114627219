import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/P3Quest.svg"
import STEP_THREE_CONTENT from "../../images/LocDeConsum.svg"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="3"
    title="Adaugă un loc de consum"
    buttonLink="/salut/createCard"
  >
    <img src={STEP_THREE_CONTENT} alt="App Store" style={{ width: "100%" }} />
    <div style={{ marginTop: 16, fontSize: 16 }}>
      <div style={{ marginBottom: 8 }}>
        1. Accesează butonul <b>Locuri de consum </b>
        din aplicație și adaugă un nou loc de consum.
      </div>
      <div style={{ marginBottom: 8 }}>
        2. Folosește numărul personal și codul unic primite de la Primărie sau
        de la Asociația de proprietari. În cazul în care nu le-ați primit, vă
        rugăm sa solicitați aceste date. 
      </div>
      <span style={{ marginTop: 8 }}>
        <b style={{ fontWeight: 600 }}>
          Atenție!  Nu confundați aceste date cu numărul de serie înscris pe
          card
        </b>
      </span>
      <div style={{ marginBottom: 6, marginTop: 6 }}>
        3. După confirmarea locului de consum poți vedea toate informațiile
        accesând butonul <b>Mai multe detalii.</b>
      </div>
    </div>
  </TutorialLayout>
)

export default Second
